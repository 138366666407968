// 보안 질문 목록
const securityQuestions = [
  "보안 질문을 선택하세요",
  "첫 학교 이름은?",
  "가장 존경하는 선생님 이름은?",
  "가장 좋아하는 영화는?",
  "가장 좋아하는 음식은?",
  "첫 직장 이름은?",
  "가장 좋아하는 책은?",
  "가장 좋아하는 음악가는?",
  "어렸을 때 살았던 거리 이름은?",
];
export default securityQuestions;
