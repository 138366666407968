import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import securityQuestions from "../../components/securityQuestions";
import "./signup.scss";

function SignupPassword() {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [securityQuestion, setSecurityQuestion] = useState(""); // 보안 질문 상태
  const [securityAnswer, setSecurityAnswer] = useState(""); //보안 질문의 답변 상태

  const [isMatch, setIsMatch] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const location = useLocation();
  const navigate = useNavigate(); // React Router의 navigate 함수 사용

  // 다음 버튼 클릭 시 처리 함수
  const handleNext = () => {
    if (password.length < 4) {
      alert("4자 이상 입력해 주세요.");
      return;
    }

    if (password !== passwordCheck) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    if (
      !securityQuestion ||
      securityQuestion === "보안 질문을 선택하세요" ||
      securityQuestion.length < 1
    ) {
      alert("보안 질문을 선택해주세요.");
      return;
    }

    if (!/^[a-zA-Z0-9\s가-힣]+$/.test(securityAnswer)) {
      alert("보안 질문 답변에는 숫자와 텍스트만 입력할 수 있습니다.");
      return;
    }
    if (securityAnswer.length < 2) {
      alert("보안 질문 답변에는 2자 이상 입력해주세요.");
      return;
    }
    // 모든 조건이 충족된 경우
    navigate("/signup/job", {
      state: {
        ...location.state,
        password: password,
        securityQuestion: securityQuestion,
        securityAnswer: securityAnswer,
      },
    });
  };

  useEffect(() => {
    setIsMatch(password === passwordCheck && passwordCheck.length > 0);
  }, [password, passwordCheck]);

  return (
    <div className="container signup-containerP">
      <div className="signup-box">
        <button className="goBack" onClick={() => navigate(-1)}>
          <img src="/images/prevBtn.png" alt="이전" />
        </button>
        <h3>비밀번호 설정</h3>
        <div className="input-container">
          <label htmlFor="password">비밀번호</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="비밀번호를 입력해주세요."
            onChange={(e) => setPassword(e.target.value)}
          />
          <ul>
            <li>4자 이상</li>
          </ul>
        </div>
        <div className="input-container">
          <label htmlFor="passwordCheck">비밀번호 확인</label>
          <input
            type="password"
            id="passwordCheck"
            name="passwordCheck"
            placeholder="비밀번호를 입력해주세요."
            onChange={(e) => setPasswordCheck(e.target.value)}
          />
          {isSubmitted && !isMatch && (
            <p className="error">*비밀번호가 일치하지 않습니다.</p>
          )}
        </div>
        <div className="input-container security">
          <label>보안 질문 (비밀번호 찾기를 위해 필요합니다):</label>
          <select
            value={securityQuestion}
            onChange={(e) => setSecurityQuestion(e.target.value)}
            required
          >
            {securityQuestions.map((question, index) => (
              <option key={index} value={question}>
                {question}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <label>보안 질문 답변:</label>
          <input
            type="text"
            value={securityAnswer}
            onChange={(e) => setSecurityAnswer(e.target.value)}
          />
        </div>
        <button className="navyBox" onClick={handleNext}>
          다음
        </button>
      </div>
    </div>
  );
}

export default SignupPassword;
